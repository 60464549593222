import axios from "axios";

const { REACT_APP_KYC_BACKEND } = process.env;
const uploadS3Url = REACT_APP_KYC_BACKEND + '/v1/site/get-presigned-url'

const generateRandomUID = () => {
    const timestamp = Date.now();
    const randomComponent = Math.random().toString(36).substring(2, 5);
    const uid = `${timestamp}_${randomComponent}`;
  
    return uid;
}
const uploadFiles = async (s3Tmp, file)=>{
    try {
        // console.log("s3Tmp=====>",s3Tmp)
        // console.log("file=====>",file)

        const response = await axios.put(s3Tmp.uploadUrl, file);
        // console.log('S3 PUT response sess ', response)

        return s3Tmp.key
       
    }catch(e){
        console.error('uploadFiles Error:', e);
        return null
    }
}
const getS3Link = async (mimeType) => {
    
    try {
        let reqInstance = axios.create({
        
        })
        const response = await reqInstance.get(uploadS3Url, {
            params:{
                mimeType:mimeType
            }
        });
        // console.log('S3 url response sess ===>', response)

        return response.data
    } catch (error) {
        console.error('getS3Link Error:', error);
        return null
    }

}

export default {
    uploadFiles, getS3Link, generateRandomUID
}
