import React from "react";
import TopBar from '../components/common/TopBar'
import BottomBar from "../components/common/BottomBar";
import VerificationConsent from "../components/ui/VerificationConsent";
const VerificationConsentPage = () => {
  const isMobileWidth = () => {
    return window.innerWidth < 774;
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: isMobileWidth() ? 'unset' : '100vh',
          height: !isMobileWidth() ? 'unset' : '100vh',
          flexDirection: 'column',
        }}
      >
        <TopBar />
        <VerificationConsent />
        <BottomBar />
      </div>
    </div>
  );
};

export default VerificationConsentPage;