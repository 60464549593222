import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import iconX from '../../resources/icon-x-red.svg'
import { useTranslation } from 'react-i18next';


const InvalidAccess = () => {
  const {t} = useTranslation();
  return (
   
    <Container
      maxWidth="false"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width:'calc(100% - 2rem)',
        maxWidth:'774px',
        height: '694px',
        border: '1px solid lightgrey',
        borderRadius: '4px',
        
      }}
    >
      <div>
        <Container disableGutters maxWidth="false"  >
          <Typography
          variant="h1"
          component="span"
          sx={{
              fontSize: '22px',
              fontWeight: 550,
              lineHeight: '24px',
              letterSpacing: '0px',
              textAlign: 'left',
              color: 'black',
              mt: 2,
              mb: 8,
              fontFamily: 'system-ui',
          }}
          >
              <div className="flex flex-col items-center">
                <img src={iconX} style={{maxWidth: '60px',}}/>
                <Typography
                  variant="h1"
                  component="span"
                  sx={{
                      fontSize: '22px',
                      fontWeight: 550,
                      lineHeight: '24px',
                      letterSpacing: '0px',
                      textAlign: 'center',
                      color: 'black',
                      mt: 3,
                      mb:3,
                      // mb: isExtraShort() ? 1 : 10,
                      fontFamily: 'system-ui'
                  }}
                  >
                      MEYZER360 eKYC
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                      color: '#333853',
                      // mt: isExtraShort()? 1 : 9,
                      mb: 5,
                      fontFamily: 'system-ui'
                  }}
                  >
                    {t("InvalidPage.msg")}
                      
                </Typography>
              </div>
          </Typography>
            
        </Container>
      </div>
    </Container>
  );
};

export default InvalidAccess;
