import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, { useState,useEffect } from 'react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import DetailsSummaryTable from '../flow/DetailsSummaryTable';
import dayjs from 'dayjs';

function SubmitConfirmation(props) {
  const {
    isLocalForeign,
    firstName,
    lastName,
    dob,
    idNumber,
    industry,
    industryCategory,
    salaryRange,
    // address,
    addressLine1,
    addressLine2,
    city,
    province,
    state,
    postal,
    expiryDate,
    issueDate,
    passportNumber,
    passportDocument,
    IdFrontPage,
    IdBackPage,
    proofOfAddress,
    email,
    mobileNumber,
    mobileCountryCode,
    employmentPassFront,
    employmentPassBack,
    finNumber,
    occupation,
    epDateOfApplication,
    epDateOfIssue,
    epDateOfExpiry,
    epGender,
    employer,
    isEpUpload} = props;

  const {t} = useTranslation();
  const [rows, setRows] = useState([])
  const [title, setTitle] = useState("")

  const [professionRows, setProfessionRows] = useState([])

  const [epRows, setEpRows] = useState([])
  const [epTitle, setEpTitle] = useState("")

  const [rowsImg, setRowsImg] = useState([])
  const [titleImg, setTitleImg] = useState("")

  function createData(label, value) {
    return { label, value }
  }

  function createDataImg(label, img, isFileObj) {
    return { label, img, isFileObj }
  }

  useEffect(()=>{
    //IdFrontPage, firstName, lastName, address, idNumber, dob
    // console.log("proofOfAddress")
    // console.log(proofOfAddress)
    if (isLocalForeign==="local") {
      populateLocal()
    }else if(isLocalForeign==="foreigner") {
      // passportDocument, expiryDate, issueDate, passportNumber, firstName, lastName, dob
      populateForeigner()
    }
    setTitleImg(t("ConfirmationDetails.Documents"))

  },[isLocalForeign])

  const populateForeigner = ()=>{
    let infoRows = [
      createData(t('AnalyzeIDForm.first'), firstName),
      createData(t('AnalyzeIDForm.last'), lastName),
      createData(t('AnalyzePassportForm.number'), passportNumber),
      createData(t('AnalyzeIDForm.dob'), dayjs(dob).format("D MMMM YYYY")),
      createData(t('AnalyzePassportForm.issue'), dayjs(issueDate).format("D MMMM YYYY")),
      createData(t('AnalyzePassportForm.expiry'), dayjs(expiryDate).format("D MMMM YYYY")),
      createData(t('AnalyzeForm.mobileNumber'), mobileCountryCode+" "+mobileNumber),
      createData(t('AnalyzeForm.email'), email),
      // createData(t('AnalyzeIDForm.industryCategory'), industryCategory),
      // createData(t('AnalyzeIDForm.salaryRange'), salaryRange),
      // createData(t('AnalyzeIDForm.occupation'), occupation),
    ]
    setRows(infoRows)
    setTitle(t('AnalyzePassportForm.passportDetails'))

    setProfessionRows([
      createData(t('AnalyzeIDForm.industryCategory'), industryCategory),
      createData(t('AnalyzeIDForm.salaryRange'), salaryRange),
      createData(t('AnalyzeIDForm.occupation'), occupation),
    ])
    let imgRows = [
      createDataImg(t("ConfirmationDetails.Passport"), passportDocument),
      isEpUpload && employmentPassFront ? createDataImg(t("ConfirmationDetails.employmentPassFront"), employmentPassFront) : "",
      isEpUpload && employmentPassBack ? createDataImg(t("ConfirmationDetails.employmentPassBack"), employmentPassBack) : "",
      //file obj
      createDataImg(t("ConfirmationDetails.ProofOfAddress"), proofOfAddress, true),
    ].filter(v=>v)

    setRowsImg(imgRows)

    if(isEpUpload){
      setEpTitle(t('AnalyzeEpForm.employmentPass'))
      setEpRows([
        createData(t('AnalyzeEpForm.finNumber'), finNumber),
        createData(t('AnalyzeEpForm.occupation'), occupation),
        createData(t('AnalyzeEpForm.employer'), employer),
        createData(t('AnalyzeEpForm.epGender'), epGender == "M" ? t('AnalyzeEpForm.male') : t('AnalyzeEpForm.female')),
        createData(t('AnalyzeEpForm.epDateOfApplication'), dayjs(epDateOfApplication).format("D MMMM YYYY") ),
        createData(t('AnalyzeEpForm.epDateOfIssue'),  dayjs(epDateOfIssue).format("D MMMM YYYY")),
        createData(t('AnalyzeEpForm.epDateOfExpiry'),  dayjs(epDateOfExpiry).format("D MMMM YYYY")),
        
      ])
    }
  }
  const populateLocal = ()=>{
    setRows([
      createData(t('AnalyzeIDForm.first'), firstName),
      createData(t('AnalyzeIDForm.last'), lastName),
      createData(t('AnalyzeIDForm.number'), idNumber),
      createData(t('AnalyzeIDForm.dob'), dayjs(dob).format("D MMMM YYYY")),
      createData(t('AnalyzeIDForm.address'), [
        addressLine1,
        addressLine2,
        city,
        province,
        postal,
        state
      ].filter(v=>v).join(", \n")),
      createData(t('AnalyzeForm.mobileNumber'), mobileCountryCode+" "+mobileNumber),
      // createData(t('AnalyzeIDForm.industryCategory'), industryCategory),
      // createData(t('AnalyzeIDForm.salaryRange'), salaryRange),
      // createData(t('AnalyzeIDForm.occupation'), occupation),
    ])
    setTitle(t('AnalyzeIDForm.idDetails'))

    setProfessionRows([
      createData(t('AnalyzeIDForm.industryCategory'), industryCategory),
      createData(t('AnalyzeIDForm.salaryRange'), salaryRange),
      createData(t('AnalyzeIDForm.occupation'), occupation),
    ])

    setRowsImg([
      createDataImg(t("ConfirmationDetails.IDFront"), IdFrontPage),
      createDataImg(t("ConfirmationDetails.IDBack"), IdBackPage),
    ])
  }
    return (
      <Suspense fallback="...loading">
      <Container
        maxWidth="false"
        sx={{
          height: '440px',
          width: '100%',
          overflowY:'auto'
        }}
      >
          <Typography
          variant="h1"
          component="div"
          sx={{
              fontSize: '22px',
              fontWeight: 550,
              lineHeight: '14px',
              letterSpacing: '0px',
              
              color: 'black',
              mt: 0,
              mb: 2,
              fontFamily: 'system-ui'
          }}
          >
            {t('Stepper.confirm')}
          </Typography>
          <Typography
            variant="h1"
            component="div"
            sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                mb: 4,
                fontFamily: 'system-ui'
            }}
          >
            {t('SubmitPage.instruction')}
          </Typography>
          <DetailsSummaryTable 
            data={rows}
            title={title}
          />

          {<DetailsSummaryTable 
            data={professionRows}
            title={t('AnalyzeIDForm.profession')}
          />}

          {isEpUpload && <DetailsSummaryTable 
            data={epRows}
            title={epTitle}
          />}

          <DetailsSummaryTable 
            data={rowsImg}
            title={titleImg}
          />
          
      </Container>
    </Suspense>
  );
}

export default SubmitConfirmation;