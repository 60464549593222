import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React from 'react';
import UploadDocWebcam from '../common/UploadDocWebcam';
import { styled } from '@mui/material/styles';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

function EpUpload({employmentPassFront, setEmploymentPassFront, employmentPassBack, setEmploymentPassBack}) {
  const {t} = useTranslation();

    return (
      <Suspense fallback="...loading">
    <Container
      maxWidth="false"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        mb: 5,
      }}
    >
        <Typography
        variant="h1"
        component="span"
        sx={{
            fontSize: '22px',
            fontWeight: 550,
            lineHeight: '24px',
            letterSpacing: '0px',
            textAlign: 'left',
            color: 'black',
            mt: 0,
            mb: 1,
            fontFamily: 'system-ui'
        }}
        >
            {t('EpUpload.UploadDescription')}
        </Typography>
        <Typography
        variant="body2" 
        component="span"
        sx={{
          fontSize: '18px',
                        fontWeight: 400,
                        lineHeight: '1.5rem',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#333853',
                        // mt: isExtraShort()? 1 : 9,
                        fontFamily: 'system-ui',
                        display:'block',
                        width:'100%',
                        marginBottom:'1rem',
                        marginTop:'0.5rem'
        }}
      >
        {t('EpUpload.uploadInstruction2')}
      </Typography>
        <Container maxWidth="false" sx={{ display: 'flex', flexWrap:'wrap', justifyContent: 'space-between', p:'0!important'}}>
          <UploadDocWebcam type={'EpFront'} document={employmentPassFront} setDocument={setEmploymentPassFront}/>
          <UploadDocWebcam type={'EpBack'} document={employmentPassBack} setDocument={setEmploymentPassBack}/>
        </Container>
        
        <Typography
        variant="body2" // Using 'body2' variant for smaller text
        component="span"
        sx={{
          fontSize: '14px', // Adjust the font size as needed
          fontWeight: 500, // Adjust the font weight as needed
          lineHeight: '1.4', // Adjust the line height as needed
          letterSpacing: '0.5px', // Adjust the letter spacing as needed
          textAlign: 'left',
          color: '#808080', // Grey color (#808080)
          mt: 2, // Adjust the margin-top as needed
          mb: 1, // Adjust the margin-bottom as needed
          fontFamily: 'system-ui',
        }}
      >
        {t('EpUpload.uploadInstruction')}
      </Typography>
    </Container>
    </Suspense>
  );
}

export default EpUpload;