import React, {useState} from "react";
import TopBar from '../components/common/TopBar'
import BottomBar from "../components/common/BottomBar";
import FlowRegular from "../components/flow/FlowRegular";
import { Container, Button, Autocomplete, TextField  } from "@mui/material";
import occupations from "../assets/occupations.json";
import industries from "../assets/industry.json";
import industryCategory from "../assets/industryCategory.json";

function Test() {
  const [occupation, setOccupation]  = useState(null);
  const [category, setCategory]  = useState(null);
  const [industry, setIndustry]  = useState({
    id: "",
    name: "",
    category: "",
    description: ""
  });
  
  const occupationOptions = Array.from(new Set(occupations));

  const [industryOptions, setIndustryOptions ]= useState(industries);
  // let industryOptions = industries;
  const industryCategories = industries.map((el) => el.category);
  const handleIndustryChange = (value) => {
    if(typeof value === 'string') {
      // const v = industryOptions.find((el) => el.name === value);
      // if(v) {
      //   setIndustry(v);
      // }else {
      //   const dv = {
      //     id: "",
      //     name: value,
      //     category: "",
      //     description: ""
      //   };
      //   setIndustry(dv);
      // }
      return;
    } 
    setIndustry(value);
  };

  return (
    <div className="p-8 w-100 flex justify-content-center">
      <Autocomplete
        value={category || ''}
        onChange={(event, newValue) => {
          setCategory(newValue || '');
          const nv= industries.filter((e)=>e.category === newValue);
           setIndustryOptions(nv);
           setIndustry(nv[1]);
          // industryOptions = industries.filter((e)=>e.category === newValue);
        }}
        inputValue={category || ''}
        onInputChange={(event, newInputValue) => {
          setCategory(newInputValue || '');
        }}
        id="occupationCategory"
        options={industryCategory}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={'category'} />}
      />
      {/* <Autocomplete
        value={occupation || ''}
        onChange={(event, newValue) => {
          setOccupation(newValue || '');
        }}
        inputValue={occupation || ''}
        onInputChange={(event, newInputValue) => {
          setOccupation(newInputValue || '');
        }}
        id="occupation"
        options={occupationOptions}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={'test'} />}
      /> */}
      <Autocomplete
        value={industry?.name || ''}
        onChange={(event, newValue) => {
          handleIndustryChange(newValue);
        }}
        inputValue={industry?.name || ''}
        onInputChange={(event, newInputValue) => {
          handleIndustryChange(newInputValue);
        }}
        id="industry"
        getOptionLabel={(option) => option?.name || ''}
        options={industryOptions}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={'test 2'} />}
      />

      {/* <TextField
        id="outlined-controlled"
        label={t("AnalyzeIDForm.industry")}
        variant="outlined"
        fullWidth
        value={industry}
        onChange={(event) => setIndustry(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
      {/* <TextField
        id="outlined-controlled"
        label={t("AnalyzeIDForm.occupation")}
        variant="outlined"
        fullWidth
        value={occupation}
        onChange={(event) => setOccupation(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
              {JSON.stringify(industry)}
    </div>
  );
}

export default Test;