import React from 'react';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import {Container } from '@mui/material';
import CustomFaceLivenessDetector from '../ui/CustomFaceLivenessDetector.jsx';

const { REACT_APP_KYC_BACKEND } = process.env;

export default ({onComplete, auditVideo, setAuditVideo, cancelLivenessTest, fetchCreateLiveness, createLivenessApiData, setCreateLivenessApiData,
loading, setLoading}) => {
  
  // const [createLivenessApiData, setCreateLivenessApiData] = useState("");
  const geturl = REACT_APP_KYC_BACKEND + '/faceliveness/getResult';
  const {t} = useTranslation();
  // Exported states from liveness test
  const [liveImage, setLiveImage] = useState(null);
  const [livenessScore, setLivenessScore] = useState(-1);
  const mediaRecorderRef = useRef(null);
  const [videoBlob, setVideoBlob] = useState(null);
  useEffect(() => {
    
    const startRecording = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
        mediaRecorderRef.current = new MediaRecorder(mediaStream, { mimeType: 'video/webm' });
        const chunks = [];
        mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        });
        mediaRecorderRef.current.addEventListener('stop', async () => {
          const videoBlob = new Blob(chunks, { type: 'video/mp4' });
          //console.log(new File([videoBlob], "output.mp4", { type: 'video/mp4' }))
          setAuditVideo(videoBlob);
        });
        mediaRecorderRef.current.start();
      } catch (error) {
        console.error('Error accessing webcam:', error);
      }
    };
    const startLiveness = async ()=>{
      // console.log("start liveness")
      await fetchCreateLiveness();
      console.log("start recording")
      startRecording();
    }
    if(loading==true) startLiveness()
    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        console.log("stop recording")
        mediaRecorderRef.current.stop();
      }
    }
  }, [loading]);
  useEffect(() => {
    // console.log("useEffect liveImage")
    if (liveImage) {
      // console.log("onComplete")
      onComplete({liveImage});
    }
  }, [liveImage]);

  const handleAnalysisComplete = async () => {
    console.log('Here')
    console.log(createLivenessApiData)
    try {
      const response = await axios.get(geturl+'/'+createLivenessApiData);
      console.log(response); // Process the response data here


      // const liveImg = new File([response.data.liveImage.data], "output.jpg", { type: 'image/jpeg' })
      // console.log(liveImg)
      setLiveImage(response.data.liveImage);
    } catch (error) {
        console.error('Error:', error);
    }
  };
  const handleCancel = ()=>{
    cancelLivenessTest()
  }
  const isMobileWidth = () => {
    return window.innerWidth < 774;
  };

  return (
    <Container
        maxWidth="false"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: isMobileWidth() ? '100%' : '650px',
            height: '500px',
            mt: 0
        }}
    >
      <ThemeProvider>
        {loading ? (
          <div style={{textAlign:'center'}}>
            <CircularProgress size={100} color="primary" />
          </div>
        ) : (
          
          <CustomFaceLivenessDetector
            sessionId={createLivenessApiData}
            region="us-east-1"
            onAnalysisComplete={handleAnalysisComplete}
            onUserCancel={handleCancel}
            disableInstructionScreen={true}
            onError={(error) => {
              console.error(error)
            }}
            hideCloseButton={true}
          />
        )}
      </ThemeProvider>
    </Container>
  );
}