import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import FileUpload from '../common/FileUpload';
import Paper from '@mui/material/Paper';

import CloseIcon from '@mui/icons-material/Close';
import FilePresentIcon from '@mui/icons-material/FilePresent';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  padding:'16px'
}));

function ProofOfAddressUpload({proofOfAddress, setProofOfAddress, s3Temp, setS3Temp}) {
  const {t} = useTranslation();
    const [proofOfAddressFile, setProofOfAddressFile] = useState([])

    useEffect(()=>{
      if(proofOfAddressFile.length){
        
        setProofOfAddress(proofOfAddressFile[0].file);

        setS3Temp(prev=>({
          ...prev,
          proofOfAddress:proofOfAddressFile[0].fileKey,
        }))
        // console.log(s3Temp)
        // console.log(proofOfAddress)
        // console.log(proofOfAddressFile)
      }
    },[proofOfAddressFile])

    //single upload atm
    const removeFile = (ev) => {
        ev.stopPropagation();
        setProofOfAddressFile([]);
        setProofOfAddress(null)
        setS3Temp(prev=>({
          ...prev,
          proofOfAddress:null
        }))
    }

    const openFile = (file) => {
      const blobData = new Blob([file], { type: file.type });
      const blobUrl = URL.createObjectURL(blobData);
      window.open(blobUrl, '_blank');

    }
    return (
      <Suspense fallback="...loading">
    <Container
      maxWidth="false"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        mb: 5,
      }}
    >
        <Typography
        variant="h1"
        component="span"
        sx={{
            fontSize: '22px',
            fontWeight: 550,
            lineHeight: '24px',
            letterSpacing: '0px',
            textAlign: 'left',
            color: 'black',
            mt: 0,
            mb: 1,
            fontFamily: 'system-ui'
        }}
        >
            {t('ProofOfAddress.UploadDescription')}
        </Typography>
        {proofOfAddressFile?.length ? 
          <Item elevation={3} 
            onClick={()=>{openFile(proofOfAddressFile[0].file)}}
            sx={{
              display:'flex',
              justifyContent:'space-between',
              cursor:'pointer'
            }}>
            
            <div>
              <FilePresentIcon sx={{marginRight:'1rem'}}></FilePresentIcon>
              <span style={{verticalAlign:'middle'}}>{proofOfAddressFile[0].file.name}</span>
            </div>
            
            <CloseIcon onClick={(ev)=>{removeFile(ev)}}></CloseIcon>
           
            
          </Item> : 
          <FileUpload file={proofOfAddressFile} setFile={setProofOfAddressFile} label={t('ProofOfAddress.UploadMessage')}/>}


        <Typography
        variant="body2" // Using 'body2' variant for smaller text
        component="span"
        sx={{
          fontSize: '14px', // Adjust the font size as needed
          fontWeight: 500, // Adjust the font weight as needed
          lineHeight: '1.4', // Adjust the line height as needed
          letterSpacing: '0.5px', // Adjust the letter spacing as needed
          textAlign: 'left',
          color: '#808080', // Grey color (#808080)
          mt: 7, // Adjust the margin-top as needed
          mb: 1, // Adjust the margin-bottom as needed
          fontFamily: 'system-ui',
        }}
      >
        {t('ProofOfAddress.UploadInstruction')}
      </Typography>
    </Container>
    </Suspense>
  );
}
export default ProofOfAddressUpload;