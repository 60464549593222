import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QRCode from 'react-qr-code';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
function TopBar() {
  const {t, i18n} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openLang = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const [hideTop, setHideTop] = React.useState(true);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleLanguageSettingClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnSelectLang = (event) => {
    if (event.currentTarget && event.currentTarget.dataset.lang) {
      const selectedLanguage = event.currentTarget.dataset.lang;
      i18n.changeLanguage(selectedLanguage);
    }
    i18n.changeLanguage(event.target.value)
    setAnchorEl(null);
  };
  const handleQRClick = () => {
    setOpen(true);
    //To do: terminate current session
  };
  const isMobileWidth = () => {
    return window.innerWidth < 774;
  };
  return (
    // <div style={{ backgroundColor: 'white', border: '1px solid lightgrey', borderBottom: 0, height: '68.98px', width: isMobileWidth() ? '100vw' : '774px', overflow: 'visible'}}>
      <div style={{ backgroundColor: 'white', border: '1px solid lightgrey', borderBottom: 0, height: '68.98px',   width: 'calc(100% - 2rem)',
      maxWidth: '774px', overflow: 'visible'}}>
      <Container maxWidth={false} style={{ height: '100%' }} >
        <Toolbar disableGutters style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <Box component="img" src={require('../../resources/logo.png')} sx={{ height: 54}} alt="Logo" />
          {/* {!isMobileWidth() && <div minWidth={false} style={{ display: 'flex', alignItems: 'center', marginLeft: '135px' }}> */}

          {/*--------------
              temp hide
          -------------*/}
          {hideTop == false && !isMobileWidth() && <div minWidth={false} style={{ display: 'flex', alignItems: 'center', marginLeft: '' }}>
            <Typography variant="body1" color="primary" component="span">
              {/* <span style={{ textDecoration: '', cursor: 'pointer', fontSize: '14px', fontWeight: 'bold' }} onClick={handleQRClick}>
                Scan QR code to perform verification on mobile
              </span> */}
              <div className=''>
              <span  style={{ textDecoration: '', cursor: 'pointer', fontSize: '14px', fontWeight: 'bold', marginLeft: '130px' }} onClick={handleQRClick}>
                {t('topbar.qrMessage')}
              </span>
              </div>
            </Typography>
            <ArrowForwardIosIcon  sx={{ color: 'blue', marginRight: '3px',}}/>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 0 }}>
              <QrCode2Icon sx={{ color: '#666' }}/>
            </div>
          </div>}

          {
            !hideTop === false && <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title={t('topbar.preview')} disableInteractive>
                <Button onClick={handleLanguageSettingClick}>
                  <LanguageIcon sx={{ color: '#666' }} alt="Language Setting"/>
                </Button>
              </Tooltip>
              <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openLang}
              onClose={handleOnSelectLang}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleOnSelectLang} value="en" data-lang="en">English</MenuItem>
              <MenuItem onClick={handleOnSelectLang} value="cn" data-lang="cn">中文</MenuItem>
            </Menu>
            </div>
          }
          {/*--------------
              temp hide
          -------------*/}
        </Toolbar>
        {open && (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{t('topbar.qrTitle')}</DialogTitle>
            <DialogContentText id="alert-dialog-slide-description" style={{ marginLeft: 24, marginRight: 24, marginBottom: 16}}>
              {t('topbar.qrInstructions')}
            </DialogContentText>
            <DialogContent style={{ display: 'flex', justifyContent: 'center', fontFamily: 'system-ui', border: '1px solid lightgrey' }}>
              <QRCode
                value={window.location.href}
                bgColor='white'
                fgColor='black'
                size={300}
              />
            </DialogContent>
            
            <DialogActions>
              <Button onClick={handleClose}>{t('topbar.qrCloseMess')}</Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    </div>
  );
}

export default function WrappedTopBar() {
  return (
      <Suspense fallback="...loading">
          <TopBar />
      </Suspense>
  )
};