import React from "react";
import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTranslation } from 'react-i18next';

function FaceVerificationResult() {

    const {t} = useTranslation();
    
  return (
    <Container
        maxWidth="false"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p:'0px!important',          
        }}
    >

        <div style={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#333853',
            // mt: isExtraShort()? 1 : 9,
            fontFamily: 'system-ui',
            display:'block',
            width:'100%',
            marginBottom:'1rem',
            marginTop:'0.5rem'
        }}
        >
            <p>{
                `${t('FaceVerification.preVerifyHeading')} ${t('FaceVerification.preVerifyHeading2')}`
            }</p>
        </div>
        <List sx={{ 
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#333853',
            pl:'1.5rem',
            // mt: isExtraShort()? 1 : 9,
            listStyleType:'decimal',
            fontFamily: 'system-ui', }}>
            
            <ListItem sx={{ px:0, display: 'list-item' }}>
                <b>{t('FaceVerification.idDoc')} </b><br />
                 {t('FaceVerification.idDocMsg')}
            </ListItem>
            <ListItem sx={{ px:0, display: 'list-item' }}>
                <b>{t('FaceVerification.connectivity')} </b> <br />
                 {t('FaceVerification.connectivityMsg')}
            </ListItem>
            <ListItem sx={{ px:0, display: 'list-item' }}>
                <b>{t('FaceVerification.appropiateEnv')} </b> <br />
                 {t('FaceVerification.appropiateEnvMsg')}
            </ListItem>
            <ListItem sx={{ px:0, display: 'list-item' }}>
                <b>{t('FaceVerification.instructions')} </b> <br />
                 {t('FaceVerification.instructionsMsg')}
            </ListItem>
            
        </List>
        <p style={{textAlign:'left',fontSize:'1rem'}}>
            <b>{t('FaceVerification.photosensitivityMsg')}</b>
        </p>
        
        
    </Container>
  );
}

export default FaceVerificationResult;
