import React from "react";
import TopBar from '../components/common/TopBar'
import BottomBar from "../components/common/BottomBar";
import InvalidAccess from "../components/flow/InvalidAccess";
const InvalidAccessPage = () => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          flexDirection: 'column',
        }}
      >
        <TopBar />
        <InvalidAccess />
        <BottomBar />
      </div>
    </div>
  );
};

export default InvalidAccessPage;