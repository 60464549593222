import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from "react-i18next";

const SubmitConfirmation = (props)=> {
  const {msg, redoCheck, setCurrentStep, setActiveStep,setPassportDocument, setIdFrontPage, setIdBackPage, setProofOfAddress} = props;
  const { t } = useTranslation();
  const goBackToIDVerify = () => {
    setCurrentStep(0);
    setActiveStep(1);
  }
    return (
    <Container
      maxWidth="false"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '440px',
        width: '100%',
      }}
    >
        <Typography
        variant="h1"
        component="span"
        sx={{
            fontSize: '22px',
            fontWeight: 550,
            lineHeight: '24px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: 'black',
            mt: 0,
            mb: 10,
            fontFamily: 'system-ui'
        }}
        >
            {msg}
        </Typography>

        {redoCheck == 1 && <Button
          variant="contained"
          color="primary"
          sx={{ fontFamily: "system-ui", borderRadius: "20px", fontSize: "17px", fontWeight: 500, textTransform: "none", mt: 3, mb: 3 }}
          onClick={goBackToIDVerify}
        >
          {t("general.back")}
        </Button>}
    </Container>
  );
}
export default SubmitConfirmation;