import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import CountrySelection from "./CountrySelection";

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    color: "#595D70",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiSvgIcon-root": {
    color: "grey",
  },
  "& .MuiMenu-paper": {
    backgroundColor: "#F5FBFF",
  },
}));
function LocalForeignCheck({ countryCode, setCountryCode, defaultCode, isLocalForeign, setIsLocalForeign, kycLocale, passportCountry, setPassportCountry }) {
  const { t } = useTranslation();
  const [nationality, setNationality] = useState("locale.sg");

  const handleLocalForeignCheck = (event) => {
    setIsLocalForeign(event.target.value);
  };

  useEffect(() => {
    switch (kycLocale) {
      case "my":
        setNationality("locale.my");
        break;
      default:
        setNationality("locale.sg"); //sg
        break;
    }
  }, [kycLocale]);

  return (
    <Suspense fallback="...loading">
      <Container
        maxWidth="false"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          mb: 27,
        }}
      >
        <Typography
          variant="h1"
          component="span"
          sx={{
            fontSize: "22px",
            fontWeight: 550,
            lineHeight: "24px",
            letterSpacing: "0px",
            textAlign: "left",
            color: "black",
            mt: 0,
            mb: 5,
            fontFamily: "system-ui",
          }}
        >
          {t("Stepper.PassportIDVerification")}
        </Typography>

        <Typography
          variant="h1"
          component="span"
          sx={{
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0px",
            textAlign: "left",
            color: "black",
            mb: 2,
            fontFamily: "system-ui",
          }}
        >
          {t("LocalForeignerCheck.question", { locale: t(nationality) })}
        </Typography>
        <FormControl sx={{ backgroundColor: "#F5FBFF", mb: 5 }}>
          <CustomSelect value={isLocalForeign} onChange={handleLocalForeignCheck}>
            <MenuItem value="local">
              <Box display="flex" alignItems="center">
                {t("LocalForeignerCheck.local", { locale: t(nationality) })}
              </Box>
            </MenuItem>
            <MenuItem value="foreigner">
              <Box display="flex" alignItems="center">
                {t("LocalForeignerCheck.foreigner")}
              </Box>
            </MenuItem>
          </CustomSelect>
        </FormControl>

        {isLocalForeign == "foreigner" && <CountrySelection setCountryCode={setCountryCode} countryCode={countryCode} passportCountry={passportCountry} setPassportCountry={setPassportCountry} />}
      </Container>
    </Suspense>
  );
}

export default LocalForeignCheck;
