import React , {useState, useEffect} from 'react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { useTranslation } from 'react-i18next';
const { REACT_APP_KYC_FRONTEND } = process.env;
const dictionary = {
  // use default strings for english
  en: null,
  cn: {
    timeoutHeaderText: '超时',
    timeoutMessageText: "未在时间限制内完成椭圆内的人脸填充。请重试，并在7秒内完全填充椭圆内的人脸。",
    faceDistanceHeaderText: '检测到前倾移动',
    faceDistanceMessageText: '连接时请避免靠近移动。',
    multipleFacesHeaderText: '检测到多张人脸',
    multipleFacesMessageText: '连接时请确保摄像头前只有一张人脸。',
    clientHeaderText: '客户端错误',
    clientMessageText: '由于客户端问题导致检查失败',
    serverHeaderText: '服务器问题',
    serverMessageText: '由于服务器问题无法完成检查',
    landscapeHeaderText: '不支持横向方向',
    landscapeMessageText: '请将您的设备旋转至纵向（竖直）方向。',
    portraitMessageText: '在检查过程中，请确保您的设备保持纵向（竖直）方向。',
    tryAgainText: '重试',
    instructionsHeaderHeadingText: '活体检测',
    instructionsHeaderBodyText: '您将进行人脸验证过程，以证明您是真实的人。',
    instructionsBeginCheckText: '开始检测',
    photosensitivityWarningHeadingText: '光敏感警告',
    photosensitivityWarningBodyText: '此检测会显示彩色灯光。如果您对光敏感，请谨慎使用。',
    photosensitivityWarningInfoText: '少数人在暴露于彩色灯光时可能会出现癫痫发作。如果您或您的家人患有癫痫病，请谨慎使用。',
    instructionListHeadingText: '按照以下说明完成检测:',
    goodFitCaptionText: '合适的状态',
    goodFitAltText: '人脸完美地适应椭圆内。',
    tooFarCaptionText: '太远了',
    tooFarAltText: '人脸位于椭圆内，脸部周围与椭圆的边界之间有间隙。',
    instructionListStepOneText: '当出现椭圆时，在7秒内将您的脸填满椭圆。',
    instructionListStepTwoText: '最大化屏幕亮度。',
    instructionListStepThreeText: '确保脸部未被太阳镜或口罩遮挡。',
    instructionListStepFourText: '前往光线充足的地方，避免阳光直射。',
    cameraMinSpecificationsHeadingText: '相机不符合最低规格要求',
    cameraMinSpecificationsMessageText: '相机必须支持至少320*240分辨率和15帧每秒。',
    cameraNotFoundHeadingText: '无法访问相机。',
    cameraNotFoundMessageText: '在重试之前，请检查相机是否连接并在设置中启用了相机权限。',
    retryCameraPermissionsText: '重试',
    cancelLivenessCheckText: '取消活体检测',
    recordingIndicatorText: '录制中',
    hintMoveFaceFrontOfCameraText: '将脸移至相机前方',
    hintTooManyFacesText: '确保相机前只有一张脸',
    hintFaceDetectedText: '检测到人脸',
    hintCanNotIdentifyText: '将脸移至相机前方',
    hintTooCloseText: '后退一点',
    hintTooFarText: '靠近一点',
    hintHoldFacePositionCountdownText: '倒计时期间保持脸部位置不变',
    hintConnectingText: '连接中...',
    hintVerifyingText: '正在验证...',
    hintIlluminationTooBrightText: '移至光线较暗的地方',
    hintIlluminationTooDarkText: '移至光线较亮的地方',
    hintIlluminationNormalText: '光线条件正常',
    hintHoldFaceForFreshnessText: '保持静止',
  },
};
export default ({hideCloseButton,
    sessionId,
    region,
    onAnalysisComplete,
    onUserCancel,
    disableInstructionScreen,
    onError}) => {
      const {t, i18n} = useTranslation();
      const [language, setLanguage] = useState('en');

      useEffect(()=>{
        setLanguage(i18n.language)
        console.log("language---->",i18n.language)
      },[i18n])
  
      

  return (
    <div style={{ position: 'relative' }}>
      {hideCloseButton && (
        <style>
          {`
            .amplify-liveness-cancel-container {
              display: none;
            }
          `}
        </style>
      )}
      <style>
        {`
        .amplify-liveness-toast__message .amplify-loader{
          width:1rem;
          height:1rem;
        }
        .amplify-loader{
          width: 100px;
          height: 100px;
        }
        .amplify-loader circle{
          stroke:transparent
        }
        .amplify-loader circle:last-of-type{
          stroke:#1976d2
        }
        .amplify-card{
          display:none
        }
        `}
      </style>
      <FaceLivenessDetector
        sessionId={sessionId}
        region={region}
        onAnalysisComplete={onAnalysisComplete}
        onUserCancel={onUserCancel}
        disableInstructionScreen={disableInstructionScreen}
        onError={onError}
        displayText={dictionary[language]}
        // tensorflow model loading cors issue fix
        // * uncomment this when deploy to production, because sometimes model cannot load from third party site
        // * comment this when doing development, to load model from third party
        // https://ui.docs.amplify.aws/react/connected-components/liveness/customization#custom-cdn

        config={{
          binaryPath: `${REACT_APP_KYC_FRONTEND}/model/`,
          faceModelUrl: `${REACT_APP_KYC_FRONTEND}/model/model.json` 
        }}
        />
    </div>
  );
};