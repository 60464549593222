import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { countriesData } from '../../helpers/all-country.js';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
        color: '#595D70',
       '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
        color: 'grey',
    },
    '& .MuiMenu-paper': {
        backgroundColor: '#F5FBFF',
    },
}));
function CountrySelection({
    countryCode,
    setCountryCode, 
    defaultCode,
    passportCountry,
    setPassportCountry
}) {
    const {t} = useTranslation();
    const [selectedPassportCountry, setselectedPassportCountry] = useState('');
    const handleselectedPassportCountryChange = (event, value) => {
        if(!value) {
            setselectedPassportCountry("");
            setPassportCountry("")
            return
        }
        setPassportCountry(value.code.toLowerCase())
        setselectedPassportCountry(value.code.toLowerCase());
        
    };
    useEffect(()=>{
        // investigate how to populate input field later
        if(defaultCode) setselectedPassportCountry(defaultCode)
    },[defaultCode])

    useEffect(()=>{
        // console.log("useEffect")
        setCountryCode(selectedPassportCountry)
    },[selectedPassportCountry])

    return (
    <Suspense fallback="...loading">
        <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                mb: 2,
                fontFamily: 'system-ui'
            }}
            >
                {t('CountrySelection.countrySelection')}
        </Typography>
        <FormControl sx={{backgroundColor:'#F5FBFF'}}>
            <Autocomplete
                id="country-select-demo"
                options={countriesData}
                autoHighlight
                getOptionLabel={(option) => option.name ?? ""}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Choose a country"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    />
                )}
                onChange={handleselectedPassportCountryChange}
            />
            
        </FormControl>
        
        <Typography
        variant="body2" // Using 'body2' variant for smaller text
        component="span"
        sx={{
          fontSize: '16px', // Adjust the font size as needed
          fontWeight: 400, // Adjust the font weight as needed
          lineHeight: '1.4', // Adjust the line height as needed
          letterSpacing: '0.5px', // Adjust the letter spacing as needed
          textAlign: 'left',
          color: 'black', // Grey color (#808080)
          mt: 3, // Adjust the margin-top as needed
          mb: 1, // Adjust the margin-bottom as needed
          fontFamily: 'system-ui',
        }}
      >
        {t('CountrySelection.instructions')}
      </Typography>
    </Suspense>
  );
}

export default CountrySelection;