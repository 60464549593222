import React from "react";
import AuthenticateToken from "../components/AuthenticateToken/AuthenticateToken";
import CircularProgress from '@mui/material/CircularProgress';

const AuthenticateTokenPage = () => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          flexDirection: 'column',
        }}
      >
        <AuthenticateToken />
        <CircularProgress size={100} color="primary" />
      </div>
    </div>
  );
};

export default AuthenticateTokenPage;