import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Container, Button, Autocomplete  } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import MobileInput from "../common/MobileInput";
import CustomDialog from "../common/CustomDialog";
import useErrors from "../../hooks/useError";


import occupations from "../../assets/occupations.json";
import industries from "../../assets/industry.json";
import industryCategories from "../../assets/industryCategory.json";
const { REACT_APP_KYC_BACKEND } = process.env;

export default ({
  passportDocument,
  expiryDate,
  issueDate,
  passportNumber,
  setExpiryDate,
  setissueDate,
  setPassportNumber,
  firstName,
  setFirstName,
  lastName,
  setlastName,
  dob,
  setDob,
  occupation,
  setOccupation,
  industry,
  setIndustry,
  industryCategory,
  setIndustryCategory,
  salaryRange,
  setSalaryRange,
  email,
  setEmail,
  mobileNumber,
  setMobileNumber,
  mobileCountryCode,
  setMobileCountryCode,
  backOneStep,
}) => {
  const { t } = useTranslation();
  const textExtractURL = REACT_APP_KYC_BACKEND + "/textExtract/extractText/passport/singapore";
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setError, clearError, getError } = useErrors();
  const occupationOptions = Array.from(new Set(occupations));
  const [industryOptions, setIndustryOptions ]= useState(occupationOptions);

  const handleIndustryChange = (value) => {
    if(typeof value === 'string') return;
    setIndustry(value);
  };

  useEffect(() => {
    const captureTextDetails = async (image) => {
      const requestData = {
        img: image,
      };
      try {
        let textDetectResponse = await axios.post(textExtractURL, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (textDetectResponse.data.doe !== "") {
          setExpiryDate(dayjs(textDetectResponse.data.doe));
        }
        if (textDetectResponse.data.doi !== "") {
          setissueDate(dayjs(textDetectResponse.data.doi));
        }
        if (textDetectResponse.data.passportNum !== "") {
          setPassportNumber(textDetectResponse.data.passportNum);
        }

        if (!textDetectResponse.data.passportNum && !textDetectResponse.data.doi && !textDetectResponse.data.doe) {
          setDialogOpen(true);
        }
      } catch (error) {
        // Handle error if needed
      } finally {
        setLoading(false);
      }
    };
    captureTextDetails(passportDocument);
  }, []);

  const validateField = (field, value) => {
    switch (field) {
      case "email":
        return !/\S+@\S+\.\S+/.test(value) ? t("Validation.email") : "";
      default:
        return !value ? t("Validation.required") : '';
    }
  };

  const handleFieldChange = (field, value) => {
    const errorMessage = validateField(field, value);
    if (errorMessage) {
      setError(field, errorMessage);
    } else {
      clearError(field);
    }

    switch (field) {
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleExpDateChange = (date) => {
    setExpiryDate(date);
  };
  const handleIssueDateChange = (date) => {
    setissueDate(date);
  };
  const handlePassportNumberChange = (event) => {
    setPassportNumber(event.target.value);
  };
  const handleFirstnameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastnameChange = (event) => {
    setlastName(event.target.value);
  };
  const handleDobChange = (date) => {
    setDob(date);
  };
  const onDialogClose = (event, reason) => {
    if (reason == "backdropClick" || reason == "escapeKeyDown") return;
    setDialogOpen(false);
  };
  const onDialogAccept = (event) => {
    //accept reupload
    setDialogOpen(false);
    backOneStep();
  };
  const onDialogReject = (event) => {
    //reject reupload
    setDialogOpen(false);
  };

  function getOccupationForm() {
    return (
      
      <div className="mt-4">
        <hr className="my-4"></hr>
        <div class="ml-1">
          <Typography
            variant="h1"
            component="span"
            sx={{
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "black",
              mb: 0,
              ml: 0,
              fontFamily: "system-ui",
            }}
            >{t("AnalyzeIDForm.subContent")}</Typography>
        </div>
        <div className="pt-4 mb-4 flex flex-col sm:flex-row gap-2">
          <div className="w-full sm:w-1/2">
            <Autocomplete
              value={industryCategory || ''}
              onChange={(event, newValue) => {
                handleFieldChange("industryCategory", newValue);
                setIndustryCategory(newValue || '');
                const nv= industries.filter((e)=>e.category === newValue);
                setIndustryOptions(nv);
                // setIndustry(nv[1]);
              }}
              inputValue={industryCategory || ''}
              onInputChange={(event, newInputValue) => {
                setIndustryCategory(newInputValue || '');
              }}
              id="industryCategory"
              options={industryCategories}
              className="w-full"
              variant="outlined"
              renderInput={(params) => <TextField  error={getError("industryCategory")} helperText={getError("industryCategory")}  {...params} label={t("AnalyzeIDForm.industryCategory")} />}
            />
          </div>
          {
            // jaynote: hide it as corpsec team said no need this
            false && (
              <div className="w-full sm:w-1/2">
                <Autocomplete
                  value={industry?.name || ''}
                  onChange={(event, newValue) => {
                    handleFieldChange("industry", newValue);
                    handleIndustryChange(newValue);
                  }}
                  inputValue={industry?.name || ''}
                  onInputChange={(event, newInputValue) => {
                    handleIndustryChange(newInputValue);
                  }}
                  id="industry"
                  getOptionLabel={(option) => option?.name || ''}
                  options={industryOptions}
                  // sx={{ width: 300 }}
                  variant="outlined"
                  fullWidth
                  renderInput={(params) => <TextField error={getError("industry")} helperText={getError("industry")}  {...params} label={t("AnalyzeIDForm.industry")} />}
                />
              </div>
            )
          }

          {
            <div className="w-full sm:w-1/2">
              <Autocomplete
                value={salaryRange || ''}
                onChange={(event, newValue) => {
                  handleFieldChange("salaryRange", newValue);
                  setSalaryRange(newValue || '');
                }}
                inputValue={salaryRange || ''}
                onInputChange={(event, newInputValue) => {
                  setSalaryRange(newInputValue || '');
                }}
                id="salaryRange"
                options={['< $2,000','$2,001 - $5,000', '$5,001 - $10,000', '> $10,000']}
                className="w-full"
                variant="outlined"
                renderInput={(params) => <TextField  error={getError("salaryRange")} helperText={getError("salaryRange")}  {...params} label={t("AnalyzeIDForm.salaryRange")} />}
              />
            </div>
          }
        </div>
        <div className="mb-4 flex flex-col sm:flex-row gap-2">
              <div  className="w-full sm:w-1/2">
                <Autocomplete
                  value={occupation || ''}
                  onChange={(event, newValue) => {
                    setOccupation(newValue || '');
                    handleFieldChange("occupation", newValue);
                  }}
                  inputValue={occupation || ''}
                  onInputChange={(event, newInputValue) => {
                    setOccupation(newInputValue || '');
                  }}
                  id="occupation"
                  options={occupationOptions}
                  // sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} error={getError("occupation")}
                  helperText={getError("occupation")} label={t("AnalyzeIDForm.occupation")} />}
                />
              </div>
        </div>


        {/* {JSON.stringify(industry)} */}
      </div>
    );
  }
  return (
    <Suspense fallback="...loading">
      <div>
        {!loading && (
          <Container
            disableGutters
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h1"
              component="span"
              sx={{
                fontSize: "22px",
                fontWeight: 550,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "black",
                mt: 0,
                mb: 2,
                ml: 0,
                fontFamily: "system-ui",
              }}
            >
              {t("AnalyzePassportForm.confirmInformation")}
            </Typography>
            <Typography
              variant="h1"
              component="span"
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "black",
                ml: 0,
                mb: 1,
                fontFamily: "system-ui",
              }}
            >
              {t("AnalyzePassportForm.content")}
            </Typography>
            <Box
              component="form"
              sx={{
                // "& .MuiTextField-root": { m: 1, width: "34ch", borderBottom: 0 },
                mt: 1,
              }}
              noValidate
            >
              <div>
                <div className="mb-4 flex flex-col sm:flex-row gap-2">
                  <div className="w-full sm:w-1/2">
                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label={t("AnalyzeIDForm.first")}
                      value={firstName}
                      variant="outlined"
                      onChange={handleFirstnameChange} // Attach the onChange event handler
                      InputLabelProps={{
                        shrink: true, // Keep the label always shrunk above the input
                      }}
                      fullWidth
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label={t("AnalyzeIDForm.last")}
                      value={lastName}
                      variant="outlined"
                      onChange={handleLastnameChange} // Attach the onChange event handler
                      InputLabelProps={{
                        shrink: true, // Keep the label always shrunk above the input
                      }}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="mb-4 flex flex-col sm:flex-row gap-2">
                  <div className="w-full sm:w-1/2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={t("AnalyzeIDForm.dob")}
                        value={dob}
                        onChange={handleDobChange}
                        format="DD/MM/YYYY" // Customize the date format
                        mask="__/__/____" // Add a mask for better input control (optional)
                        renderInput={(params) => <TextField {...params} variant="filled" />}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="w-full sm:w-1/2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={t("AnalyzePassportForm.expiry")}
                        value={expiryDate}
                        onChange={handleExpDateChange}
                        format="DD/MM/YYYY" // Customize the date format
                        mask="__/__/____" // Add a mask for better input control (optional)
                        renderInput={(params) => <TextField {...params} variant="filled" />}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="mb-4 flex flex-col sm:flex-row gap-2">
                  <div className="w-full sm:w-1/2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={t("AnalyzePassportForm.issue")}
                        value={issueDate}
                        onChange={handleIssueDateChange}
                        format="DD/MM/YYYY" // Customize the date format
                        mask="__/__/____" // Add a mask for better input control (optional)
                        renderInput={(params) => <TextField {...params} variant="filled" />}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="w-full sm:w-1/2">
                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label={t("AnalyzePassportForm.number")}
                      value={passportNumber}
                      variant="outlined"
                      onChange={handlePassportNumberChange} // Attach the onChange event handler
                      InputLabelProps={{
                        shrink: true, // Keep the label always shrunk above the input
                      }}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="mb-4 flex flex-col sm:flex-row gap-2">
                  <div className="w-full sm:w-1/2">
                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label={t("AnalyzeForm.email")}
                      value={email}
                      variant="outlined"
                      onChange={(e) => handleFieldChange("email", e.target.value)} // Attach the onChange event handler
                      InputLabelProps={{
                        shrink: true, // Keep the label always shrunk above the input
                      }}
                      error={getError("email")}
                      helperText={getError("email")}
                      className="w-full"
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <MobileInput
                      mobileNumber={mobileNumber}
                      setMobileNumber={setMobileNumber}
                      mobileCountryCode={mobileCountryCode}
                      setMobileCountryCode={setMobileCountryCode}
                      //onChange={handleMobileNumberCHange} // Attach the onChange event handler
                    />
                  </div>
                </div>

                {getOccupationForm()}
              </div>
            </Box>
          </Container>
        )}
        {loading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100px" // Set the desired height (adjust as needed)
            marginTop="170px"
            marginBottom="170px"
          >
            <CircularProgress size={100} color="primary" /> {/* Adjust the size and color */}
          </Box>
        )}

        <CustomDialog
          onClose={onDialogClose}
          open={dialogOpen}
          content={
            <Typography
              variant="h1"
              component="div"
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "center",
                color: "black",
                mb: 2,
                fontFamily: "system-ui",
              }}
            >
              {t("AnalyzePassportForm.ocrRetryMsg")}
              <br />
              {t("AnalyzePassportForm.ocrRetryConfirmation")}
            </Typography>
          }
          footer={
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ minWidth: "100px", fontFamily: "system-ui", borderRadius: "20px", fontSize: "17px", fontWeight: 500, textTransform: "none" }}
                onClick={onDialogAccept}
              >
                {t("general.yes")}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ minWidth: "100px", fontFamily: "system-ui", borderRadius: "20px", fontSize: "17px", fontWeight: 500, textTransform: "none" }}
                onClick={onDialogReject}
              >
                {t("general.no")}
              </Button>
            </>
          }
        />
      </div>
    </Suspense>
  );
};
