import React, { useState } from 'react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUploader } from "react-drag-drop-files";
import s3Upload from "../../helpers/s3Upload"
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';


function FileUpload({file,setFile,label}) {
    const fileTypes = ["JPG", "PNG", "PDF"];
    const {t} = useTranslation();
    const uploadFiles = s3Upload.uploadFiles
    const getS3Link = s3Upload.getS3Link
    const [uploading, setUploading] = useState(false)
    

    const handleChange = async (uploadedFile) => {
        setUploading(true)
        const imgS3Link = await getS3Link(uploadedFile.type)
        uploadFiles(imgS3Link,uploadedFile).then(res=>{

            setFile(prev=>[...prev, {
                file:uploadedFile,
                fileKey:res
            }]);
            setUploading(false)
            
        }).catch(err=>{
            console.error(err)
            setUploading(false)
        })
    };

    // const removeFile = (index) => {
    //     const updatedFile = [...file.slice(0, index), ...file.slice(index + 1)];
    //     setFile(updatedFile);
    // }


    // prop: children	
    // JSX Element, any	
    // if you want to replace the current design inside the box of drop zone. (it will remove the default exist style)	
    // <div><p>this is inside drop area</p></div> or just text
    return (
        <Suspense fallback="...loading">
            {!uploading && <FileUploader handleChange={handleChange} name="file" types={fileTypes} classes='fileUpload-custom' label={label}/>}
            {uploading && <CircularProgress/>}
            {/* {
                file.map((f,key)=>
                    <div key={key}>
                        <div >{f?.file?.name}</div>
                        <div onClick={(ev)=>removeFile(key)}>delete</div>
                    </div>
                )
            } */}
        </Suspense>
    );
}

export default FileUpload;