import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React, { useContext } from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import StoreContext from "../../contexts/StoreContext";
import Button from "@mui/material/Button";
const {REACT_APP_CENTRAL_FRONTEND} = process.env;

function SubmitCompletion() {
  const { queryParam } = useContext(StoreContext);
  const { t } = useTranslation();
  return (
    <Suspense fallback="...loading">
      <Container
        maxWidth="false"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "490px",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          component="span"
          sx={{
            fontSize: "22px",
            fontWeight: 550,
            lineHeight: "24px",
            letterSpacing: "0px",
            textAlign: "center",
            color: "black",
            mt: 0,
            mb: 10,
            fontFamily: "system-ui",
          }}
        >
          {queryParam?.source === "cv2" || queryParam?.source === 'central' ?  t("SubmitPage.successCv2") : t("SubmitPage.success")}
        </Typography>

        {queryParam?.source === "cv2" && (
          <Button
            variant="contained"
            href="https://cv2.meyzer360.site"
            sx={{
              alignSelf: "center",
              borderRadius: "20px",
              width: "25%",
              minWidth: '180px',
            }}
          >
            Finish
          </Button>
        )}
        {queryParam?.source === "central" && (
          <Button
            variant="contained"
            href={REACT_APP_CENTRAL_FRONTEND}
            sx={{
              alignSelf: "center",
              borderRadius: "20px",
              width: "25%",
              minWidth: '180px',
            }}
          >
            Finish
          </Button>
        )}
      </Container>
    </Suspense>
  );
}

export default SubmitCompletion;
